import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useMedia } from "../../hooks/use-media"
import { useStyle } from "../../hooks/use-style"
import { useUser } from "../../hooks/use-user"
import { darkTheme, lightTheme } from "../../services/theme"
import Avatar from "../layout/avatar"

type Props = {
  borderless?: boolean
  autofocus?: boolean
  placeholder?: string
  initialValue?: string
  commentId?: string
  onSubmit?: (amount: number, text: string, commentId: string) => void
  onCancel?: () => void
  maxChars?: number
}

const DonationInput: FunctionComponent<Props> = ({
  onSubmit: submit,
  onCancel: cancel,
  borderless = false,
  autofocus = false,
  placeholder,
  initialValue = "",
  commentId = "",
  maxChars = 512,
}) => {
  const { user } = useUser()

  const [text, setText] = useState(initialValue)
  const [rows, setRows] = useState(1)
  const [donationAmount, setDonationAmount] = useState(0)
  const { numColumns } = useMedia()

  const onFocus = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    //event.currentTarget.placeholder = ''
  }, [])

  const onKeyPress = useCallback(
    (event: ChangeEvent<KeyboardEvent>) => {
      //console.log("onKeyPress ")
      if (event.charCode == 13 || event.key === "Enter") {
        if (!(event.shiftKey || event.ctrlKey) && text.length > 0) {
          if (submit) submit(1, text, commentId)
          setText("")
          setRows(1)
          event.preventDefault()
        } else if (event.ctrlKey) {
          setText(text + "\n")
          setRows(rows + 1)
        }
      }
    },
    [text, initialValue]
  )

  const onChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (event.currentTarget.value.length > maxChars) return

      setText(event.currentTarget.value)
      const textareaLineHeight = 18

      const previousRows = event.target.rows
      event.target.rows = 1 // reset number of rows in textarea
      const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)

      if (currentRows === previousRows) {
        event.target.rows = currentRows
      }

      /*if (currentRows >= maxRows) {
              event.target.rows = maxRows;
              event.target.scrollTop = event.target.scrollHeight;
          }*/

      setRows(currentRows)
    },
    [maxChars]
  )

  const onAmountChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.value.length == 0) {
      setDonationAmount("")
      return
    } else {
      let amount = parseFloat(event.currentTarget.value)
      if (amount > 0) {
        setDonationAmount(amount)
      } else {
        setDonationAmount(0)
      }
    }
  }, [])

  const onSubmit = useCallback(() => {
    if (submit && donationAmount > 0) {
      submit(donationAmount, text, "")
      setText("")
    }
  }, [submit, text, donationAmount])
  const onCancel = useCallback(() => {
    if (cancel) {
      cancel()
    }
    setText("")
  }, [cancel])

  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (autofocus) {
      ref.current?.focus()
    }
  }, [])

  const css = useStyle(theme => ({
    backgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
    borderColor:
      theme === "light" ? lightTheme.color.border : darkTheme.color.border,
    inputBorderColor:
      theme === "light" ? lightTheme.color.border : "transparent",
    focusColor:
      theme === "light" ? lightTheme.color.active : darkTheme.color.active,
    activeButtonBackgroundColor:
      theme === "light"
        ? lightTheme.color.background.secondary
        : darkTheme.color.background.primary,
  }))

  const onKeyUp = event => {
    /*
    if (event.keyCode === 13) {
      event.preventDefault()
      state.like(Math.floor(likeAmount * 100))
      state.close()
    }*/
  }

  return (
    <>
      {numColumns == 1 ? (
        <div className="component" style={{ display: "block" }}>
          <div style={{ display: "flex", width: "100%" }}>
            <div
              style={{
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              {user && (
                <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  <div className="link">
                    <Avatar
                      image={user.profile.image ?? undefined}
                      label={user.profile.title}
                      size={28}
                    />
                    <strong className="title">{user.profile.title}</strong>
                  </div>
                </div>
              )}

              <div style={{ overflow: "initial" }}>
                <input
                  id="pledge"
                  type="number"
                  step="0.01"
                  value={donationAmount}
                  onChange={onAmountChange}
                  className="amount"
                  onKeyUp={onKeyUp}
                  style={{ maxWidth: "60px", marginRight: "10px" }}
                />
                <button onClick={onSubmit} className="submit">
                  Donate
                </button>
              </div>
            </div>
          </div>
          <div style={{}}>
            <textarea
              rows={rows}
              ref={ref}
              placeholder={placeholder}
              value={text}
              onChange={onChange}
              onFocus={onChange}
            />
            {text.length > 0 && (
              <div style={{ textAlign: "right", fontSize: "13px" }}>
                {text.length}/{maxChars}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="component">
          <div style={{ display: "table-row" }}>
            <div style={{ display: "table-cell" }}>
              <textarea
                rows={rows}
                ref={ref}
                placeholder={placeholder}
                value={text}
                onChange={onChange}
                onFocus={onChange}
              />
              {text.length > 0 && (
                <div style={{ textAlign: "right", fontSize: "13px" }}>
                  {text.length}/{maxChars}
                </div>
              )}
            </div>

            <div className="amountCell">
              <input
                id="pledge"
                type="number"
                step="0.01"
                value={donationAmount}
                onChange={onAmountChange}
                className="amount"
                onKeyUp={onKeyUp}
              />
            </div>
            <div className="submitCell">
              <button onClick={onSubmit} className="submit">
                Donate
              </button>
            </div>
          </div>
        </div>
      )}

      <style jsx>{`
        .submitCell {
          width: 95px;
          display: table-cell;
          vertical-align: middle;
          text-align: right;
        }
        .submit {
          width: 90px;
          font-family: "Roboto";
          padding: 2px;

          color: inherit;
          background-color: transparent;
          border: 1px solid ${css.borderColor};
          padding: 5px;
          transition: 200ms border-color, 200ms background-color, 200ms color;
          z-index: 1;
          border-radius: 0.3em;
          background-color: ${css.activeButtonBackgroundColor};
        }
        .submit:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
        .amountCell {
          display: table-cell;
          vertical-align: top;
          padding-left: 10px;
          padding-right: 5px;
          width: 100px;
        }
        .amount {
          width: 100%;
          text-align: right;
          font-family: "Roboto";
          font-size: 14px;
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        .amount:focus,
        .amount:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }

        .textArea {
          padding: 5px;

          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          font-size: 14px;
          width: 100%;
          color: inherit;
          min-height: 3.3em;
          line-height: 130%;
        }
        .title {
          white-space: nowrap;
          margin-left: 5px;
          margin-right: 5px;
        }
        .link {
          min-width: 50px;
          display: flex;
          align-items: center;
          text-decoration: none;
          font-family: Roboto;
          font-size: 14px;
        }

        .component {
          display: table;
          width: 100%;
        }
        header {
          display: flex;
          align-items: center;
        }
        header > :not(:first-child) {
          margin-left: 0.5em;
        }
        .content {
          flex: 1;
        }
        .content > :not(:first-child) {
          margin-top: 1em;
        }
        .bordered {
          padding-top: 1em;
          border-top: 1px solid ${css.borderColor};
          transition: 200ms border-color;
        }
        .toolbar {
          margin-top: 0.75em;
        }
        .toolbar span:not(:first-child) {
          margin-left: 0.1em;
        }
        .toolbar span:not(:last-child) {
          margin-right: 0.1em;
        }
        textarea {
          background-color: ${css.backgroundColor};
          border: 1px solid ${css.inputBorderColor};
          transition: 200ms background-color, 200ms border-color,
            200ms box-shadow;
          border-radius: 0.4em;
          font-size: 14px;
          padding: 5px;
          resize: vertical;
          width: 100%;
          color: inherit;
          /*min-height: 3.3em;*/
          line-height: 18px;
        }
        textarea:focus,
        textarea:hover {
          box-shadow: 0 0 0 1px ${css.focusColor};
        }
      `}</style>
    </>
  )
}

export default DonationInput
